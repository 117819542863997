import  {React,useState,useEffect }from 'react';
import featuresa from '../assets/img/features-1.svg';
import featuresb from '../assets/img/features-2.svg';
import featuresc from '../assets/img/features-3.svg';
import featuresd from '../assets/img/features-4.svg';
import xcc        from         '../assets/img/why-us.jpg';
import { Link } from 'react-router-dom';
function Home() {

    const [activeSlide, setActiveSlide] = useState(0);
    const slides = [
      {
        title: 'Welcome to <span>Nano Security</span>',
        content: 'Nano Security is a trusted cybersecurity consulting firm dedicated to helping organizations    their digital assets from evolving threats. Our team of expert security professionals specializes in providing comprehensive cybersecurity solutions, including mobile app security, web application security, API security, and more.',
        button: 'Read More',
      },
      {
        title: 'Penetration testing to identify vulnerabilities in your systems, networks, and applications',
        content: 'Our penetration testing services simulate real-world attacks to identify vulnerabilities in your systems, networks, and applications. Our team of certified ethical hackers will identify weaknesses, prioritize risks, and provide actionable recommendations to remediate vulnerabilities, ensuring your organization is better equipped to withstand cyber threats.',
        button: 'Read More',
      },
      {
        title: 'Comprehensive Security Risk Assessments ',
        content: 'Proactively identify and mitigate potential threats early, includes  identify, analyze, and prioritize vulnerabilities and threats, enabling organizations to proactively implement effective security measures and safeguard their valuable assets  ',
        button: 'Read More',
      },
      {
        title: 'Cyber Incident Response & Breach Response ',
        content: 'Immediate action and recovery from cyber incidents, contain, investigate, and recover from cyberattacks, protecting an organization assets and reputation.',
        button: 'Read More',
      },
      {
        title: 'Digital Forensics Service . Have you been hacked ? or Suspicious activity on your network ? ',
        content: 'Fear not, our digital forensics experts will uncover the truth, providing the answers and peace of mind you deserve ,by investigate, leaving no stone unturned to reveal the full story behind the breach ',
        button: 'Read More',
      },
      {
        title: 'Network Infrastructure Setup ,Maintenance and Support',
        content: 'Design and implement robust network infrastructures tailored to your business needs, ensuring optimal connectivity and performance. ',
        button: 'Read More',
      },

    
    
      {
        title: 'Domain Controller & Active Directory Setup and Configuration',
        content: 'Manage user accounts, group policies, and security settings within your Active Directory domain to ensure secure and controlled access. ',
        button: 'Read More',
      },
      {
        title: 'Outlook Email Setup and Configuration',
        content: 'Configure Outlook to meet your specific email requirements, including calendars, contacts, and task management. ',
        button: 'Read More',
      },
      {
        title: 'GOVERNANCE AND REGULATORY COMPLIANCE SECURITY CONSULTING',
        content: 'Nano Security  Governance and Regulatory Compliance, Cybersecurity Consulting, and Cyber Security Advisory assist organizations with their cyber risk and compliance needs , Our cybersecurity consulting team has extensive cybersecurity experience, and we are a leader in managed cybersecurity consulting.',
        button: 'Read More',
      }
  
    

    ];
  
    const handlePrev = () => {
      setActiveSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
    };
  
    const handleNext = () => {
      setActiveSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    };
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        handleNext();
      }, 5000); // Adjust the interval as needed
  
      return () => clearInterval(intervalId);
    }, []);

  return (
    <main id="main">
       
   <section id="hero" className="d-flex justify-content-center align-items-center">
      <div className="container">
        <div id="heroCarousel" className="carousel carousel-fade">
          <div className="carousel-inner">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`carousel-item ${index === activeSlide ? 'active' : ''}`}
              >
                <div className="carousel-container">
                  <h2
                    className="animate__animated animate__fadeInDown"
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  />
                  <p className="animate__animated animate__fadeInUp">{slide.content}</p>
                  <a href="" className="btn-get-started animate__animated animate__fadeInUp">
                    {slide.button}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <Link
            className="carousel-control-prev"
            to=""
            role="button"
            data-bs-slide="prev"
            onClick={handlePrev}
          >
            <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
          </Link>
          <Link 
           to=""
            className="carousel-control-next"
           
            role="button"
            data-bs-slide="next"
            onClick={handleNext}
          >
            <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
          </Link>
        </div>
      </div>
    </section>
      {/* ======= Services Section ======= */}
  
      <section className="services">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="index-2.html">Penetration Testing </a>
                </h4>
                <p className="description">
                  Test the resilience of your infrastructure to cyber threats.
                  We provide a high-end solution that can be tailored to any
                  business. It uses information on the latest methods and
                  techniques used in attacks collected by NANO SECURITY Threat
                  Intelligence, and involves both manual work and the use of
                  more than 40 automated tools. It helps your organization stop
                  breaches, prevent ransomware, pass compliance, it including the
                  following
                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                    External infrastructure
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                    Internal infrastructure
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                    Mobile Applications
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                    Web Applications
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="index-2.html">
                  Comprehensive Security Risk Assessments
                  </a>
                </h4>
                <p className="description">
                  Our team of experts will make Comprehensive Security Risk Assessments on your networks ,applications and provide you 
                  with insght about how your organization is secure and safe 
                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Asset Identification                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Threat Assessment
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Vulnerability Assessment
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Impact Analysis
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Risk Calculation and Prioritization
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Risk Mitigation and Control Implementation
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up" >
              <div className="icon-box icon-box-green">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="index-2.html">
                    Incident Response

                  </a>
                </h4>
                <p className="description">
                Cyberattacks are inevitable, but their impact doesn't have to be. 
                Our experienced Incident Response team delivers rapid containment, 
                thorough investigation, and expert recovery. The outcome:


                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  restored operations
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  actionable insights to prevent future incidents
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                    Executive summary for business
                  </li>
               
                </ul>
              </div>
            </div>


            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up" >
              <div className="icon-box icon-box-green">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="index-2.html">
                  Digital Forensics Service

                  </a>
                </h4>
                <p className="description">
                Been hacked? Data Breached? Our Digital Forensics experts meticulously examine your digital landscape,
                uncovering the hidden clues and revealing the full story of the attack,
                by employ cutting-edge techniques to recover, analyze, and interpret digital evidence ,The outcome:


                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Get actionable evidence
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Informed decision-making
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  The power to hold perpetrators accountable.
                  </li>
               
                </ul>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up" >
              <div className="icon-box icon-box-blue">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="">
                  Cybersecurity Training  

                  </a>
                </h4>
                <p className="description">
                Tailored training for a tailored defense.
                We understand that every organization is unique.
                Our Cybersecurity Training programs are customized to your specific needs and risks,
                ensuring maximum relevance and impact ,The outcome:


                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  empowered employees
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  a proactive security culture,
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  resilient defense against evolving cyber threats.
                  
                  </li>
               
                </ul>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-6 d-flex align-items-stretch"
              data-aos="fade-up" >
              <div className="icon-box icon-box-blue">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="">
                  GOVERNANCE AND REGULATORY COMPLIANCE SECURITY CONSULTING  

                  </a>
                </h4>
                <p className="description">
                Nano Security  Governance and Regulatory Compliance.
                Cybersecurity Consulting.
                Security Advisory assist organizations with their cyber risk and compliance needs,
                Our cybersecurity consulting team has extensive cybersecurity experience
                we are a leader in managed cybersecurity consulting.


                </p>
            
              </div>
            </div>

            <div
              className="col-md-12 col-lg-12 d-flex align-items-stretch"
              data-aos="fade-up" >
              <div className="icon-box icon-box-blue">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  {' '}
                  <a href="">
                  Building Secure IT Infrastructure

                  </a>
                </h4>
                <p className="description">
                Nano Security helps its customers transform their IT infrastructure security by implementing secure infrastructure that makes Your company no longer needs to pay for expensive IT security consultants. 
                Our cyber security and compliance experts will deliver the best IT security consulting services while reducing cost and time for your business to become compliant                    
                includes:

                </p>
                <ul>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Server & Network Device Configration and Hardening
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Network & Web Service security,
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Data Security.
                  
                  </li>
                
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Outlook Email Setup and Configuration.
                  
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Domain Controller & Active Directory Setup and Configuration.
                  
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  Backups.
                  
                  </li>
                  <li style={{ direction: 'ltr', textAlign: 'left' }}>
                  SSL Certifactes.
                  
                  </li>
               
                </ul>
              </div>
            </div>




          </div>
        </div>
      </section>
      {/* End Services Section */}

      {/* ======= Why Us Section ======= */}
      <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
        <div className="container">
          <h1>Why partner with us</h1>

          <div className="row">
            <div className="col-lg-6 video-box">
              <img src={xcc} className="img-fluid" alt="" />
              <a
                href="https://youtube.com/shorts/0HJ6XjEI2u0?feature=share"
                className="venobox play-btn mb-4"
                data-vbtype="video"
                data-autoplay="true"
              ></a>
            </div>

            <div
              className="col-lg-6 d-flex flex-column justify-content-center p-5"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-fingerprint"></i>
                </div>
                <h4 className="title">
                  <a href="">Advanced Technology with Best Practices</a>
                </h4>
                <p className="description">
                  provide organizations with a more robust and comprehensive
                  cybersecurity posture. This can include cutting-edge threat
                  intelligence, advanced analytics, and scalable infrastructure
                  to support large-scale cybersecurity operations.
                </p>
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-gift"></i>
                </div>
                <h4 className="title">
                  <a href="">Cost-Effective Solutions</a>
                </h4>
                <p className="description">
                  By partnering with a local partner, organizations can benefit
                  from cost-effective cybersecurity solutions that are tailored
                  to their specific needs. Local partners often have a deeper
                  understanding of the local market and can provide solutions
                  that are more affordable and effective than those offered by
                  larger, international providers
                </p>
              </div>
              
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-support"></i>
                </div>
                <h4 className="title">
                  <a href="">Unmatched Expertise and Support</a>
                </h4>
                <p className="description">
                  By partnering with us , organizations can benefit
                  from the avaliablity of our teams in 24 hours a day , 7 days in week 
                  on your hand
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* End Why Us Section */}

      {/* ======= Features Section ======= */}
      <section className="features">
        <div className="container">
          <div className="section-title">
            <h2>Features</h2>
            <p>
            From the speed of innovation, digital transformation, and economic expectations to evolving cyber threats, the talent gap, and a dynamic regulatory landscape, technology leaders are expected to effectively respond to and manage these competing priorities.

            To grow securely while reducing risk, your cybersecurity posture needs to adapt and respond to your business changing. As technology rapidly evolves and digital adoption accelerates, NanoSecurtiy's cybersecurity and privacy team turns risk into an advantage – protecting every layer of an organization to unlock new opportunities, securely.</p>
            <p>
            Our strategic and technical subject matter experts fully understand your cybersecurity needs. We set out to assess, develop, implement, and manage end-to-end next-generation solutions tailored to your specific needs. We share your commitment to protecting your data and optimizing your business and cyber resiliency.
            </p>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-5">
              <img
                src={featuresa}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-4">
              <h3>Securing your future with trust and confidence              </h3>
              <p className="fst-italic">
              At NanoSecurtiy, we help you transform your business — securely — one step at a time through our comprehensive technology consulting services.
               We focus on achieving your goals to manage security strategy, enable compliance and trust, protect your data and business assets, transform and optimize your business, architect and sustain your security platforms, and enable your security resilience plans.
              </p>
            
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-5 order-1 order-md-2">
              <img
                src={featuresb}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-5 order-2 order-md-1">
              <h3>Grow with confidence              </h3>
              <p className="fst-italic">
              Build cybersecurity into your transformation efforts: cost optimization, 
              security operation and effective merger-and-acquisition planning.
              </p>

            </div>
          </div>



   
        </div>
      </section>
      {/* End Features Section */}
    </main>
   
  );
}

export default Home;