import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import "swiper/css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { Pagination } from "swiper/modules";
import ab from "../assets/img/about.jpg";
import c1 from "../assets/img/clients/client-1.png";
import c2 from "../assets/img/clients/client-2.png";
import c3 from "../assets/img/clients/client-3.png";
import c4 from "../assets/img/clients/client-4.png";
import c5 from "../assets/img/clients/client-5.png";
import c6 from "../assets/img/clients/client-6.png";
import c7 from "../assets/img/clients/client-7.png";
import c8 from "../assets/img/clients/client-8.png";
import t1 from "../assets/img/testimonials/testimonials-1.jpg";
import t2 from "../assets/img/testimonials/testimonials-2.jpg";
import t3 from "../assets/img/testimonials/testimonials-3.jpg";
import t4 from "../assets/img/testimonials/testimonials-4.jpg";
import t5 from "../assets/img/testimonials/testimonials-5.jpg";
const About = () => {
  const [clients, setClients] = useState([
    { src: c1, alt: "" },
    { src: c2, alt: "" },
    { src: c3, alt: "" },
    { src: c4, alt: "" },
    { src: c5, alt: "" },
    { src: c6, alt: "" },
    { src: c7, alt: "" },
    { src: c8, alt: "" },
  ]);
  
  const [About, setTAbout] = useState([
    {
      img: t1,
      name: "Saul Goodman",
      title: "Ceo & Founder",
      text: "Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.",
    },
    {
      img: t2,
      name: "Sara Wilsson",
      title: "Designer",
      text: "Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.",
    },
    {
      img: t3,
      name: "Jena Karlis",
      title: "Store Owner",
      text: "Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.",
    },
    {
      img: t4,
      name: "Matt Brandon",
      title: "Freelancer",
      text: "Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.",
    },
    {
      img: t5,
      name: "John Larson",
      title: "Entrepreneur",
      text: "Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.",
    },
  ]);

  return (
    <main id="main">
      {/* ======= About Us Section ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>About Us</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End About Us Section */}

      {/* ======= About Section ======= */}
      <section className="about" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={ab} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>
            NanoSecurity is a Yemeni consulting firm that delivers deep expertise, objective insights, a tailored approach and unparalleled collaboration to help leaders confidently face the future.
             Our consulting solutions span critical business problems in technology, digital, business process, analytics, risk, compliance, transactions and internal audit.
              We are committed to attracting and developing a diverse workforce of professionals who share the values of integrity, inclusion and innovation. We believe that by teaming together with our clients, we can tackle the business challenges presented by a dynamic world and discover and implement innovative, efficient and effective business solutions.

              </h3>
           
            </div>
          </div>
        </div>
      </section>
      {/* End About Section */}

      {/* ======= Facts Section ======= */}
      <section className="facts section-bg" data-aos="fade-up">
      <div className="container">
        <div className="row counters">
          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={10} duration={1} />
            <p>Clients</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={20} duration={1} />
            <p>Projects</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={1463} duration={1} />
            <p>Hours Of Support</p>
          </div>

          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={15} duration={1} />
            <p>Hard Workers</p>
          </div>
        </div>
      </div>
    </section>
      {/* End Facts Section */}

      {/* ======= Clients Section ======= */}
      {/* <section className="clients" data-aos="fade-up">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <Swiper
            modules={[Pagination]}
            loop={true}
            speed={600}
            autoplay={{ delay: 5000 }}
            slidesPerView="auto"
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 60,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 80,
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 120,
              },
            }}
            className="clients-swiper"
          >
            <div className="swiper-wrapper align-items-center">
              {clients.map((client, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={client.src}
                    className="img-fluid"
                    alt={client.alt}
                  />
                </SwiperSlide>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </Swiper>
        </div>
      </section>
      End Clients Section */}

      {/* ======= Our Skills Section =======
      <section className="skills" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Our Skills</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div>
          <div className="skills-content">
            <div className="progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }} // Set width to 100%
              >
                <span className="skill">
                  HTML <i className="val">100%</i>
                </span>
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-info"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "90%" }} // Set width to 90%
              >
                <span className="skill">
                  CSS <i className="val">90%</i>
                </span>
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "75%" }} // Set width to 75%
              >
                <span className="skill">
                  JavaScript <i className="val">75%</i>
                </span>
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                aria-valuenow="55"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "55%" }} // Set width to 55%
              >
                <span className="skill">
                  Photoshop <i className="val">55%</i>
                </span>
              </div>
            </div>
          
          </div>
        </div>
      </section> */}
      {/* End Our Skills Section */}
      {/* End Our Skills Section */}

      {/* ======= Tetstimonials Section ======= */}

      {/* <section className="testimonials" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Testimonials</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div>
          <div className="testimonials-carousel swiper">
            <Swiper
              modules={[Pagination]}
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              autoplay={{ delay: 5000 }}
              navigation={true}
              pagination={{ clickable: true }}
            >
              <div className="swiper-wrapper">
                {About.map((About, index) => (
                  <SwiperSlide key={index}>
                    <div className="testimonial-item">
                      <img src={About.img} className="testimonial-img" alt="" />
                      <h3>{About.name}</h3>
                      <h4>{About.title}</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        {About.text}
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
      </section> */}
      {/* End Ttstimonials Section */}
    </main>
  );
};

export default About;
