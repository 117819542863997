import React from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'; // Corrected import
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import  './assets/vendor/aos/aos.css';
import 'aos/dist/aos.css';
import './assets/css/style.css';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/img/apple-touch-icon.png';
import Nave from './compnaont/nave';
import Fotter from './compnaont/fotter';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/servece';
import Team from './pages/teams';
import Contact from './pages/contact';


function App() {
  React.useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration (in milliseconds)
      easing: 'ease-in-out', // Animation easing
      once: true, // Only animate once per element
    });
  }, []);
  return (
    <div className="App">
    

      <BrowserRouter>
        <Nave />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/pages/about" element={<About/>} />
          <Route path="/pages/servece" element={<Services/>} />
          <Route path="/pages/contact" element={<Contact/>} />
        </Routes>
        <Fotter />
      </BrowserRouter>
    </div>
  );
}

export default App;