// import React from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
// const Contact = () => {
//   return (
//     <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
//         <br/>
//         <section class="breadcrumbs">
//       <div class="container">

//         <div class="d-flex justify-content-between align-items-center">
//           <h2>Contact</h2>
//           <ol>
//             <li><a href="index.html">Home</a></li>
//             <li>Contact</li>
//           </ol>
//         </div>

//       </div>
//     </section>
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-6">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="info-box">
//                   <i className="bx bx-map"></i>
//                   <h3>Our Address</h3>
//                   <p>Hadaa Street,Sanaa</p>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="info-box">
//                   <i className="bx bx-envelope"></i>
//                   <h3>Email Us</h3>
//                   <p>info@nanosecurity.network<br />admin@nanosecurity.network</p>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="info-box">
//                   <i className="bx bx-phone-call"></i>
//                   <h3>Call Us</h3>
//                   <p>+967 782 582 552<br />+967 733 xxx xxx</p>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-6">
//             <form action="forms/contact.php" method="post" role="form" className="php-email-form">
//               <div className="row">
//                 <div className="col-md-6 form-group">
//                   <input
//                     type="text"
//                     name="name"
//                     className="form-control"
//                     id="name"
//                     placeholder="Your Name"
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group mt-3 mt-md-0">
//                   <input
//                     type="email"
//                     className="form-control"
//                     name="email"
//                     id="email"
//                     placeholder="Your Email"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="form-group mt-3">
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="subject"
//                   id="subject"
//                   placeholder="Subject"
//                   required
//                 />
//               </div>
//               <div className="form-group mt-3">
//                 <textarea
//                   className="form-control"
//                   name="message"
//                   rows="5"
//                   placeholder="Message"
//                   required
//                 ></textarea>
//               </div>
//               <div className="my-3">
//                 <div className="loading">Loading</div>
//                 <div className="error-message"></div>
//                 <div className="sent-message">Your message has been sent. Thank you!</div>
//               </div>
//               <div className="text-center">
//                 <button type="submit">Send Message</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
 
//     </section>
//   );
// };

// export default Contact;

// import React, { useState } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
// import axios from 'axios'; // add axios library

// const Contact = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [subject, setSubject] = useState('');
//   const [message, setMessage] = useState('');
//   const [sending, setSending] = useState(false);
//   const [sent, setSent] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setSending(true);

//     try {
//       const response = await axios.post('https://nanosecurity.network/api/contact.php', {
//         name,
//         email,
//         subject,
//         message,
//       });

//       if (response.data.success) {
//         setSent(true);
//       } else {
//         console.error(response.data.error);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setSending(false);
//     }
//   };

//   return (
//     <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
//       <br />
//       <section className="breadcrumbs">
//         <div className="container">
//           <div className="d-flex justify-content-between align-items-center">
//             <h2>Contact</h2>
//             <ol>
//               <li><a href="index.html">Home</a></li>
//               <li>Contact</li>
//             </ol>
//           </div>
//         </div>
//       </section>
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-6">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="info-box">
//                   <i className="bx bx-map"></i>
//                   <h3>Our Address</h3>
//                   <p>Hadaa Street,Sanaa</p>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="info-box">
//                   <i className="bx bx-envelope"></i>
//                   <h3>Email Us</h3>
//                   <p>info@nanosecurity.network<br />admin@nanosecurity.network</p>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="info-box">
//                   <i className="bx bx-phone-call"></i>
//                   <h3>Call Us</h3>
//                   <p>+967 782 582 552<br />+967 733 xxx xxx</p>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-6">
//             <form onSubmit={handleSubmit} className="php-email-form">
//               <div className="row">
//                 <div className="col-md-6 form-group">
//                   <input
//                     type="text"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     name="name"
//                     className="form-control"
//                     id="name"
//                     placeholder="Your Name"
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group mt-3 mt-md-0">
//                   <input
//                     type="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     name="email"
//                     className="form-control"
//                     id="email"
//                     placeholder="Your Email"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="form-group mt-3">
//                 <input
//                   type="text"
//                   value={subject}
//                   onChange={(e) => setSubject(e.target.value)}
//                   name="subject"
//                   className="form-control"
//                   id="subject"
//                   placeholder="Subject"
//                   required
//                 />
//               </div>
//               <div className="form-group mt-3">
//                 <textarea
//                   value={message}
//                   onChange={(e) => setMessage(e.target.value)}
//                   name="message"
//                   rows="5"
//                   placeholder="Message"
//                   required
//                 ></textarea>
//               </div>
//               <div className="my-3">
//                 <div className="loading">Loading</div>
//                 <div className="error-message"></div>
//                 <div className="sent-message">Your message has been sent. Thank you!</div>
//               </div>
//               <div className="text-center">
//                 {sending ? (
//                   <button type="submit" disabled>
//                     Sending...
//                   </button>
//                 ) : (
//                   <button type="submit">Send Message</button>
//                 )}
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Contact;
import React, { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState({
    sending: false,
    error: null,
    success: false
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ sending: true, error: null, success: false });

    try {
      const response = await axios.post('/contact.php', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        setStatus({ sending: false, error: null, success: true });
        setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
      } else {
        throw new Error(response.data.message || 'Failed to send message');
      }
    } catch (error) {
      setStatus({
        sending: false,
        error: error.message || 'An error occurred while sending the message',
        success: false
      });
    }
  };

  return (
    <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
      {/* Previous address section remains the same */}
      
      <div className="col-lg-6">
        <form onSubmit={handleSubmit} className="php-email-form">
          <div className="row">
            <div className="col-md-6 form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="col-md-6 form-group mt-3 mt-md-0">
              <input
                type="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                required
              />
            </div>
          </div>
          <div className="form-group mt-3">
            <input
              type="tel"
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Phone Number"
              required
            />
          </div>
          <div className="form-group mt-3">
            <input
              type="text"
              name="subject"
              className="form-control"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
            />
          </div>
          <div className="form-group mt-3">
            <textarea
              name="message"
              rows="5"
              className="form-control"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              required
            ></textarea>
          </div>
          <div className="my-3">
            {status.sending && <div className="loading">Loading</div>}
            {status.error && <div className="error-message">{status.error}</div>}
            {status.success && <div className="sent-message">Your message has been sent. Thank you!</div>}
          </div>
          <div className="text-center">
            <button type="submit" disabled={status.sending}>
              {status.sending ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;