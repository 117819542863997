import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';

function Nave() {
  const [showNav, setShowNav] = useState(false); // State to control nav visibility

  const toggleNav = () => {
    setShowNav(!showNav); // Toggle the state
  };

  return (
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" className="img-fluid" />
           
          </Link>
        </div>
       
        <nav id="navbar" className={`navbar ${showNav ? 'show' : ''}`}> {/* Add 'show' class if showNav is true */}
        
          <ul>
            <li>
              <Link className="active" to="/">
                Home
              </Link>
            </li>
       
            <li>
              <Link to="../pages/servece">Services</Link>
            </li>
            {/* <li>
              <Link to="../pages/team">Team</Link>
            </li> */}
            <li>
              <Link to="../pages/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="../pages/about">About</Link>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleNav}></i> {/* Add click handler */}
        </nav>
      </div>
      {/* <script src="../src/assets/js/contact.js"></script> */}
    </header>
  );
}

export default Nave;