import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


function Footer() {
  return (
    <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
  

      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="../pages/about">About</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="../pages/servece">Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="../pages/team">Team</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="../pages/contact">Contact Us</Link>
                </li>
              </ul>
            </div> */}

            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to="../pages/servece">Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div> */}

            <div className="col-lg-6 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                Sanaa Haddah Street <br />
                Yemen <br />
                <br />
                <strong>Phone:</strong> +967 782 582 552 <br />
                <strong>Email:</strong> info@nanosecurity.network <br />
              </p>
            </div>

            <div className="col-lg-6 col-md-6 footer-info">
              <h3>About NanoSecurity</h3>
              <p>
              <strong> Your Trusted Security Consulting Advisor .</strong>
              </p>
              <div className="social-links mt-3">
                <a href="#" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </a>
                <a href="#" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </a>
                <a href="#" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </a>
                <a href="#" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>Nano Security</span></strong>. All Rights Reserved 2024
        </div>
      
      </div>
    </footer>
  );
}

export default Footer;
