import React from 'react';
import { Link } from 'react-router-dom';
import servicedetailsa from '../assets/img/service-details-1.jpg';
import servicedetailsb from '../assets/img/service-details-2.jpg';
import servicedetailsc from '../assets/img/service-details-3.jpg';
import servicedetailsd from '../assets/img/service-details-4.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function Services() {
  return (
    <main id="main">

    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Our Services</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Our Services</li>
          </ol>
        </div>

      </div>
    </section>
    <section class="services">
      <div class="container">

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up">
            <div class="icon-box icon-box-pink">
              <div class="icon"><i class="bx bxl-dribbble"></i></div>
              <h4 class="title"><a href="">Attack and Penetration Test </a></h4>
              <p class="description"><strong>Using our advanced penetration testing expertise, we identify vulnerabilities and provide actionable remediation guidance. Assuming an “attacker mindset” to replicate any scenario, we leverage best-in-class commercial security tools, leading freeware, the top open-source tools, and the latest penetration testing techniques.</strong></p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="icon-box icon-box-cyan">
              <div class="icon"><i class="bx bx-file"></i></div>
              <h4 class="title"><a href=""> Security Risk Assessments</a></h4>
              <p class="description"><strong>Our team of experts will make Comprehensive Security Risk Assessments on your networks ,applications and provide you with insght about how your organization is secure and safe</strong></p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-green">
              <div class="icon"><i class="bx bx-tachometer"></i></div>
              <h4 class="title"><a href=""> Cyber Incident Response & Breach Response              </a></h4>
              <p class="description"><strong>Our experienced Incident Response team delivers rapid containment, thorough investigation, and expert recovery. The outcome: </strong></p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-blue">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href=""> Digital Forensics Service              </a></h4>
              <p class="description"><strong>Our Digital Forensics experts meticulously examine your digital landscape, uncovering the hidden clues and revealing the full story of the attack</strong></p>
            </div>
          </div>
                   <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-blue">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href=""> Cybersecurity Training & GOVERNANCE </a></h4>
              <p class="description"><strong>Our cybersecurity consulting team has extensive cybersecurity experience we are a leader in managed cybersecurity consulting.</strong></p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-blue">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href=""> Building Secure IT Infrastructure   </a></h4>
              <p class="description"><strong>from the network devices to software we work to make everything secure.</strong></p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-blue">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href=""> Data Protection              </a></h4>
              <p class="description"><strong>Confidently maintain and protect your data, wherever it lives. include encryption , data access , protact over networks and more </strong></p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box icon-box-blue">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href=""> Building Secure IT Infrastructure   </a></h4>
              <p class="description"><strong>from the network devices to software we work to make everything secure.</strong></p>
            </div>
          </div>

        </div>

      </div>
    </section>

    
    <section class="service-details">
      <div class="container">

        <div class="row">
          <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
            <div class="card">
              <div class="card-img">
                <img src={servicedetailsa} alt="..."/>
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="#">Our Mission</a></h5>
                <p class="card-text"><strong>Our mission is to make the world a better place; our focus is to move cyber security in the right direction by defending and protecting our clients,
                   customers, and partners from theft of sensitive data through rapid incident response, cyber security programs, threat hunting, and deployment of cybersecurity proactive protective measures</strong></p>
                <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
            <div class="card">
              <div class="card-img">
                <img src={servicedetailsc} alt="..."/>
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="#">Our Vision</a></h5>
                <p class="card-text"><strong>To uplifting enterprise security, ensuring confidentiality, integrity, and availability beyond best practices</strong></p>
                <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
     
        </div>

      </div>
    </section>
    {/* <section class="pricing section-bg" data-aos="fade-up">
      <div class="container">

        <div class="section-title">
          <h2>Pricing</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row no-gutters">

          <div class="col-lg-4 box">
            <h3>Free</h3>
            <h4>$0<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li class="na"><i class="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
              <li class="na"><i class="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
            </ul>
            <a href="#" class="get-started-btn">Get Started</a>
          </div>

          <div class="col-lg-4 box featured">
            <h3>Business</h3>
            <h4>$29<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i class="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" class="get-started-btn">Get Started</a>
          </div>

          <div class="col-lg-4 box">
            <h3>Developer</h3>
            <h4>$49<span>per month</span></h4>
            <ul>
              <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i class="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" class="get-started-btn">Get Started</a>
          </div>

        </div>

      </div>
    </section> */}

  </main>
  );
}

export default Services;
